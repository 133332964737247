import React from "react"

const NewsletterFireworks = () => {
  return (
    <div className="NewsletterFireworks">
      <div className='NewsletterFireworks__wrap'>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
        <div className='NewsletterFireworks__firework'>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
          <div className='NewsletterFireworks__c'></div>
        </div>
      </div>
    </div>
  )
}

export default NewsletterFireworks

